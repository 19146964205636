import PaginationData from "@/models/general/PaginationData";
import RelativeRelation from "./RelativeRelation";
import RelativeRelationsFilter from "./RelativeRelationsFilter";

export default class RelativeRelations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.relativeRelationsData = [];
    this.relativeRelation = new RelativeRelation();
    this.filterData = new RelativeRelationsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.relativeRelationPagination);
    this.relativeRelationsData =
      data.relativeRelationPagination.relativeRelationsData;
  }
}
