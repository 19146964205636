import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import RelativeRelationImg from "@/assets/images/relativeRelations.svg";
import RelativeRelationsFilter from "./RelativeRelationsFilter";

export default class RelativeRelation {
  defaultImg = RelativeRelationImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.relativeRelationToken = "";
    this.relativeRelationNameAr = "";
    this.relativeRelationNameEn = "";
    this.relativeRelationNotes = "";
    this.relativeRelationArchiveStatus = "";
  }
  fillData(data) {
    this.relativeRelationToken = data.relativeRelationToken;
    this.relativeRelationNameAr = data.relativeRelationNameAr;
    this.relativeRelationNameEn = data.relativeRelationNameEn;
    this.relativeRelationNotes = data.relativeRelationNotes;
    this.relativeRelationArchiveStatus = data.relativeRelationArchiveStatus;
  }

  async getAllRelativeRelations(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new RelativeRelationsFilter()
  ) {
    try {
      let response = await axios.get(
        `/RelativeRelations/GetAllRelativeRelations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&relativeRelationArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfRelativeRelations(
    language,
    userAuthorizeToken,
    filterData = new RelativeRelationsFilter()
  ) {
    return await axios.get(
      `/RelativeRelations/GetDialogOfRelativeRelations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&relativeRelationArchiveStatus=false&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async getRelativeRelationDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/RelativeRelations/GetRelativeRelationDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&relativeRelationToken=${this.relativeRelationToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateRelativeRelation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      relativeRelationToken: this.relativeRelationToken,
      relativeRelationNameEn: this.relativeRelationNameEn,
      relativeRelationNameAr: this.relativeRelationNameAr,
      relativeRelationNotes: this.relativeRelationNotes,
    };

    try {
      if (
        this.relativeRelationToken == "" ||
        this.relativeRelationToken == undefined
      ) {
        return await axios.post(`/RelativeRelations/AddRelativeRelation`, data);
      } else {
        return await axios.post(
          `/RelativeRelations/UpdateRelativeRelation`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveRelativeRelation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      relativeRelationToken: this.relativeRelationToken,
    };

    try {
      return await axios.post(
        `/RelativeRelations/ArchiveRelativeRelation`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
